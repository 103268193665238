/* About.css */
.about-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left-column {
  flex-basis: 60%;
  padding: 20px;
}

.right-column {
  flex-basis: 40%;
  padding: 20px;
}

.text-section {
  color: var(--primary-text-color);
}

.text-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.text-section p {
  line-height: 1.5;
}

.team-section {
  display: flex;
  justify-content: center;
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.team-card img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-card h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.team-card p {
  font-size: 14px;
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  background-color: var(--background-color);
  border-radius: 50%;
  color: var(--primary-text-color);
  text-decoration: none;
}

.social-links a:hover {
  box-shadow: var(--inset-box-shadow);
}

.LinkedInIcon:hover {
  color: #0077b5;
}

.TwitterIcon:hover {
  color: #1da1f2;
}

/* Media query for screens less than 768px */
@media (max-width: 767px) {
    .about-container {
      flex-wrap: wrap;
    }
  
    .left-column,
    .right-column {
      flex-basis: 100%;
    }
  }