.add-salary-intro {
  margin: 10px 0 50px 0;
  text-align: left;
}

.add-salary-intro-title {
  display: block;
  font-size: calc(16px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
  color: var(--header-text-color);
}

.add-salary-intro-subtitle {
  display: block;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  width: 380px;
  color: var(--header-text-color);
}

.salary-container,
.yoe-container,
.yace-container {
  width: 380px;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.error-message {
  margin-top: 10px;
}

.error-submit {
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .salary-container,
  .yoe-container,
  .yace-container {
    width: 100%;
  }

  .intro-subtitle {
    width: 100%;
  }
}
