.JobList {
  margin: 1rem 1rem 1rem 1rem;
}

.jobs-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 400px;
}

.jobs-search-field {
  flex: 1;
  align-self: flex-start;
}

.small-screen-table table,
.large-screen-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--primary-color);
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--primary-text-color);
}

.small-screen-table th,
.large-screen-table th,
.small-screen-table td,
.large-screen-table td {
  padding: 0.75rem;
  text-align: left;
}

.small-screen-table th,
.large-screen-table th {
  background-color: var(--table-stripe-color);
  font-weight: 600;
  text-transform: uppercase;
}

.small-screen-table tr:nth-child(2n),
.large-screen-table tr:nth-child(2n) {
  background-color: var(--table-stripe-color);
}

.job-link {
  color: var(--link-text-color);
}

/* Hide the small-screen-table by default */
.small-screen-table {
  display: none;
}

/* On larger screens, hide the small-screen-table and show the large-screen-table */
@media (min-width: 1024px) {
  .small-screen-table {
    display: none;
  }
  .large-screen-table {
    display: block;
  }
  th {
    font-size: 16px;

    text-align: left;
  }
  td {
    font-size: 14px;
  }
}

/* On smaller screens, hide the large-screen-table and show the small-screen-table */
@media (max-width: 1023px) {
  .search-container {
    width: 286px;
  }

  .small-screen-table {
    display: block;
  }
  .large-screen-table {
    display: none;
  }

  td[data-label="Job Title"] {
    font-size: 14px;
  }

  td[data-label="Specialty"],
  td[data-label="Setting"],
  td[data-label="Compensation"],
  td[data-label="Location"],
  td[data-label="Date Posted"],
  td[data-label="Employment Type"] {
    font-size: 12px;
  }

  .small-screen-table table,
  .small-screen-table thead,
  .small-screen-table tbody,
  .small-screen-table th,
  .small-screen-table td,
  .small-screen-table tr {
    display: block;
  }

  .small-screen-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .small-screen-table tr {
    padding: 1.5rem 0.5rem 1.5rem 0.5rem;
  }

  .small-screen-table td {
    border: none;
    text-align: left;
    padding: 0.1rem;
  }

  .small-screen-table td[data-label="Job Title"] {
    font-weight: bold;
    /* margin-bottom: 0.25rem; */
  }

  .small-screen-table td[data-label="Specialty"] {
    display: inline-block;
    width: 30%;
    font-weight: bold;
  }

  .small-screen-table td[data-label="Setting"] {
    display: inline-block;
    width: 40%;
    text-align: center;
    font-weight: bold;
  }

  .small-screen-table td[data-label="Compensation"] {
    display: inline-block;
    width: 30%;
    text-align: right;
    font-weight: bold;
  }

  .small-screen-table td[data-label="Location"] {
    display: inline-block;
    width: 30%;
    margin-top: -0.5rem;
  }

  .small-screen-table td[data-label="Date Posted"] {
    display: inline-block;
    width: 40%;
    text-align: center;
    margin-top: -0.5rem;
  }

  .small-screen-table td[data-label="Employment Type"] {
    display: inline-block;
    width: 30%;
    text-align: right;
    margin-top: -0.5rem;
  }
}
