.button {
  padding: 0.3rem 1.2rem;
  border: none;
  color: #fff;
  text-decoration: none;
}

.button-primary {
  padding: 0.4rem 1.2rem;
  background: var(--primary-button-color);
  color: var(--primary-button-font-color);
  border: none;
  border-radius: 9px;
  position: relative;
  transform: translate(0);
  transform-style: preserve-3d;
  z-index: 1;
}

.button-primary:before {
  content: "";
  position: absolute;
  inset: -2px;
  transform: translate3d(3px, 3px, -1px);
  background: var(--primary-button-background-gradient);
  filter: blur(15px);
  border-radius: inherit;
  z-index: -1;
}

.button-primary:hover {
  background: var(--primary-button-color-hover);
  color: var(--primary-button-font-color);
  box-shadow: var(--inset-box-shadow);
}

.button-secondary {
  background: var(--secondary-button-color);
  color: var(--secondary-button-font-color);
}

.button-secondary:hover,
.button-secondary:focus,
.button-secondary.active {
  background: var(--secondary-button-color-hover);
  color: var(--link-text-color);
  box-shadow: var(--inset-box-shadow);
  border-radius: 14px;
}

.button-dropdown {
  --bs-button-color: var(--filter-button-color);
  --bs-button-bg: var(--filter-button-font-color);
  --bs-button-border-color: var(--filter-button-color);
  --bs-button-box-shadow: var(--outset-box-shadow);
  --bs-button-border-radius: 9px;
  --bs-button-hover-color: var(--filter-button-color);
  --bs-button-hover-bg: var(--filter-button-font-color);
  --bs-button-hover-border-color: var(--filter-button-color);
}

.button-dropdown > button,
/* TODO: clean up using IDs instead of raw CSS paths */
button#dropdown-basic-button.dropdown-toggle.dropdown-toggle {
  background: var(--filter-button-color);
  color: var(--filter-button-font-color);
  border: none;
  box-shadow: var(--outset-box-shadow);
  border-radius: 9px;
}

.button-dropdown > button:hover,
.button-dropdown > button:focus,
.button-dropdown > button.show {
  box-shadow: var(--inset-box-shadow);
}

.error-dropdown > .dropdown-toggle {
  border: 1px solid var(--error-color) !important;
  box-shadow: var(--outset-box-shadow-danger) !important;
}

.button-dropdown.add-salary-dropdowns,
#dropdown-button-addon {
  background: var(--filter-button-color);
  color: var(--filter-button-font-color);
  border: none;
  box-shadow: var(--outset-box-shadow);
  border-radius: 9px;
}

#dropdown-button-addon {
  margin-left: 20px;
}

/* TODO: clean up using IDs instead of raw CSS paths */
button#dropdown-basic-button.dropdown-toggle.dropdown-toggle:hover,
button#dropdown-basic-button.dropdown-toggle.dropdown-toggle:focus,
button#dropdown-basic-button.dropdown-toggle.dropdown-toggle.show {
  box-shadow: var(--inset-box-shadow);
}

.dropdown-menu.show {
  margin-top: 4px;
  background: var(--filter-button-color);
  color: var(--filter-button-font-color);
  border: none;
  box-shadow: var(--outset-box-shadow);
  height: auto;
  width: auto;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item.show {
  color: var(--link-text-color);
  background: none;
}

.dropdown-item {
  position: relative !important;
  gap: 20px
}

/* TODO: clean up using IDs instead of raw CSS paths */
button.button-chip {
  background: var(--filter-button-color);
  color: var(--filter-button-font-color);
  border: none;
  box-shadow: var(--outset-box-shadow);
  border-radius: 20px;
}

/* TODO: clean up using IDs instead of raw CSS paths */
button.button-chip:hover,
button.button-chip:focus,
button.button-chip:not(:disabled):not(.disabled):active {
  background: var(--filter-button-color);
  color: var(--filter-button-font-color);
  box-shadow: var(--inset-box-shadow);
}

.form-expansion-button {
  margin-right: 12px;
  background: var(--filter-button-color) !important;
  color: var(--filter-button-font-color) !important;
  border: none !important;
  box-shadow: var(--outset-box-shadow);
  border-radius: 9px;
}

.form-expansion-button:hover,
.form-expansion-button.active {
  box-shadow: var(--inset-box-shadow);
}

.form-expansion-button.active .add-icon {
  transform: rotate(45deg);
}

.add-icon {
  transition: transform 0.3s ease-in-out;
}

.chevron-icon {
  transition: transform 0.3s ease-in-out;
}

.chevron-icon.active {
  transform: rotate(90deg);
}

@media (max-width: 520px) {
  .form-expansion-button {
    margin-bottom: 12px;
  }
}