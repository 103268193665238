.salary-filters {
    display: flex;
    align-items: top; /* change from flex-start to center */
    justify-content: space-around;
    width: 100%;
  }
  
  .salary-filters-dropdown {
    margin-right: 1rem;
    margin-left: 20px;
    border-radius: 10px;
  }
  
  .salary-filters-button-group {
    display: flex;
    flex-wrap: wrap;
    row-gap: .5rem;
    width: 100%;
    margin-right: 0.5rem;
  }
  
  .salary-filters-button-group .button-chip{
    font-size: 0.9rem;
    padding: 0.2rem 0.6rem 0.2rem 0.6rem;
    margin-left: 0.5rem;
    border-radius: 20px;
  }
  
  /* Media query for screens less than 768px */
  @media (max-width: 480px) {
    .salary-filters {
      align-items: center;
      flex-direction: column;
    }
  
    .salary-filters-dropdown {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
  