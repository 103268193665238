.salary-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .salary-dashboard-container h2 {
    padding-top: 2rem;
    padding-bottom: .4rem;
    font-size: 16px;
    color: var(--primary-text-color);
    width: 100%;
    padding-left: 20px;
  }

  .salary-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 95%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    .salary-dashboard-container {
      gap: 20px;
    }

    .salary-dashboard-container h2 {
      font-size: 20px;  /* Set a minimum font size for small screens */
    }
  }

  @media (min-width: 1200px) {

    .salary-dashboard-container {
      gap: 25px;
    }

    .salary-dashboard-container h2 {
        font-size: 24px;  /* Set a maximum font size for large screens */
    }
}