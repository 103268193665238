/* typography.css */

.section-heading {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: var(--header-text-color);
}

.section-subheading {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--subheader-text-color);
}

.text-error {
  color: var(--error-color);
}