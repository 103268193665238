.Salaries {
  margin: 1rem 1rem 1rem 1rem;
}

.salaries-intro {
  text-align: left;
}

.salaries-intro-title {
  display: block;
  font-size: calc(16px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
  color: var(--header-text-color);
}

.salaries-intro-subtitle {
  display: block;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  width: 380px;
  color: var(--header-text-color);
}

.salaries-search-field {
  flex: 1;
  /* margin-right: 1rem; */
  align-self: flex-start;
  width: 100%
}

.salaries-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin-top: 2rem;
}

.salaries-search-field {
  flex: 1;
  align-self: flex-start;
}

.salaries-table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  /* box-shadow: var(--outset-box-shadow); */
  border: 1px solid var(--primary-color);
  color: var(--primary-text-color);
}

.salaries-table th {
  background-color: var(--table-stripe-color);
  text-align: left;
  padding: 0.75vw 4vw 0.75vw 0.75vw;
}

.salaries-table td {
  text-align: left;
  padding: 0.75vw 4vw 0.75vw 0.75vw;
}

.salaries-table td > div {
  padding: 0;
}

.salaries-table tr:nth-child(even) {
  background-color: var(--table-stripe-color);
}

.salaries-table tr:hover {
  background-color: var(--table-hover-color);
}

.th1 {
  color: var(--primary-text-color);
  /* font-size: 18px; */
  font-size: calc(12px + 0.4vw);
  font-weight: 600;
  text-transform: none;
}

.th2 {
  color: var(--secondary-text-color);
  /* font-size: 16px; */
  font-size: calc(12px + 0.3vw);
  font-weight: 600;
  text-transform: none;
}

.td1 {
  color: var(--primary-text-color);
  /* font-size: 16px; */
  font-size: calc(12px + 0.3vw);
}

.td2 {
  color: var(--secondary-text-color);
  /* font-size: 14px; */
  font-size: calc(12px + 0.2vw);
}

/* For column specialty - left aligned */
.col-specialty .th1,
.col-specialty .th2,
.col-specialty .td1,
.col-specialty .td2 {
  text-align: left;
}

/* For column location - center aligned */
.col-location .th1,
.col-location .th2,
.col-location .td1,
.col-location .td2 {
  text-align: center;
}

/* For column years - center aligned */
.col-years .th1,
.col-years .th2,
.col-years .td1,
.col-years .td2 {
  text-align: center;
}

/* For column comp - right aligned */
.col-comp .th1,
.col-comp .th2,
.col-comp .td1,
.col-comp .td2 {
  text-align: right;
}

.col-chevron {
    padding-right: 0px !important;
}

.row-details {
  background-color: var(--secondary-color) !important;
  /* border: 1px solid var(--primary-color); */
  /* box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75),
      4px 4px 6px rgba(255, 255, 255, 0.25),
      inset -1px -1px 4px rgba(255, 255, 255, 0.4),
      inset -2px -2px 6px rgba(255, 255, 255, 0.2),
      inset 1px 1px 4px rgba(0, 0, 0, 0.2), inset 2px 2px 6px rgba(0, 0, 0, 0.4); */
}

.details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 5rem;
  margin: 20px;
  width: max-content;
  /* width: 100%; */
}

.detail {
  display: flex;
  flex-direction: column;
}

.detail h3 {
  font-size: 16px;
}

.salaries-detail-table {
  margin-top: 0px;
  text-align: left !important;
}

.salaries-detail-table td {
  background-color: var(--secondary-color) !important;
  padding: 0.3rem !important;
  text-align: left;
  width: 50%;
  /* width: max-content; */
}

.row-details:hover,
.details-container:hover,
.salaries-detail-table:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 799px) {

  .details-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .details-container {
    margin: 2vw;
    display: grid;
    grid-template-columns: 1fr;
  }

  .detail {
    border: 1px solid var(--primary-color);
    padding: 2vw;
    border-radius: 5px;
  }
}

@media (max-width: 530px) {
  .salaries-intro-subtitle {
    width: 80%;
  }

  .salaries-search-container {
    width: 100%;
  }

  .salaries-table .col-years {
    display: none;
  }

  .detail h3 {
    font-size: calc(12px + 0.4vw);
  }

  .salaries-detail-table td {
    font-size: calc(12px + 0.3vw);
  }
}