.App {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.App-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "logo page-nav cta";
  align-items: center;
  background: var(--header-color);
  min-height: 150px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
  color: var(--header-text-color);
}

.logo img {
  width: 180px;
}

.header-section {
  display: flex;
  align-items: center;
}

.logo {
  grid-area: logo;
  justify-self: start;
}

.page-nav a {
  margin: 0 20px;
  text-decoration: none;
  color: inherit;
}

.page-nav {
  grid-area: page-nav;
}

.cta {
  grid-area: cta;
  justify-self: end;
  display: flex;
  justify-content: flex-end;
}

.intro {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  align-items: center;
  justify-items: start;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px 60px 20px;
}

.hero-text {
  font-size: 2.5em;
  color: var(--hero-text-color);
  justify-self: end;
}

.sub-text {
  font-size: 1.2em;
  color: var(--sub-text-color);
}

.intro-divider {
  height: 80%;
  width: 1px;
  background: var(--divider-color);
}

.gradient-text {
  background: linear-gradient(
    271.5deg,
    #9763a1 7.13%,
    rgba(121, 141, 185, 0.88684) 41.62%,
    rgba(84, 191, 215, 0.75) 53.62%,
    rgba(53, 69, 214, 0.75) 74.89%,
    rgba(111, 40, 202, 0.75) 88.58%,
    rgba(109, 7, 238, 0.75) 101.41%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: var(--hero-text-color); /* Fallback color */
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1200px) {
  .hero-text {
    font-size: calc(2vw + 12px);
  }

  .sub-text {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .App-header {
    min-height: 150px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "logo cta"
      "page-nav page-nav";
    text-align: center;
    padding: 20px 20px;
  }

  .logo,
  .cta {
    justify-self: stretch;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .page-nav {
    justify-self: center;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .page-nav a {
    margin: 0 5px;
  }

  .logo img {
    width: 130px;
  }

  .cta {
    font-size: 12px;
  }

  .intro {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1px 1fr;
    text-align: center;
    padding: 20px;
    gap: 20px;
    justify-items: center;
    align-items: center;
  }

  .hero-text {
    font-size: calc(3vw + 12px);
    justify-self: center;
  }

  .divider {
    width: 20%;
    height: 1px;
  }

  .sub-text {
    font-size: 1em;
    width: 283px;
  }
}
