.section {
  padding: 40px 20px 20px 20px;
  margin-bottom: 1rem;
}

.section-form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--section-color);
  color: var(--primary-text-color);
  border-color: var(--section-border-color);
  border-radius: 9px;
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.75),
    -4px -4px 6px rgba(255, 255, 255, 0.25), 2px 2px 6px rgba(0, 0, 0, 0.1),
    1px 1px 6px rgba(0, 0, 0, 0.5), inset 2px 2px 6px rgba(255, 255, 255, 0.5);
  padding: 2rem 3rem 2rem 3rem; /* top, right, bottom, left */
}

.section-subform-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 380px;
  background-color: var(--section-color);
  color: var(--primary-text-color);
  border-color: var(--section-border-color);
  border-radius: 9px;
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.75),
    -4px -4px 6px rgba(255, 255, 255, 0.25), 2px 2px 6px rgba(0, 0, 0, 0.1),
    1px 1px 6px rgba(0, 0, 0, 0.5), inset 2px 2px 6px rgba(255, 255, 255, 0.5);
  padding: 2rem 3rem 2rem 3rem; /* top, right, bottom, left */
}

.section-divider {
  margin: 2rem auto;
  height: 1px;
  width: 100%;
  background: var(--divider-color);
}

@media (max-width: 425px) {
  .section {
    padding: 40px 10px 20px 10px;
  }

  .section-form-container {
    padding: 1.5rem 0.5rem 1.5rem 1rem; /* top, right, bottom, left */
  }

  .section-subform-container {
    padding: 1.5rem 0.5rem 1.5rem 1rem; /* top, right, bottom, left */
    width: 100%;
  }
}
