input[type="text"] {
  padding: 0.5rem;
  height: 38px;
  font-size: 1rem;
  color: var(--input-text-font-color);
  width: 100%;
  box-sizing: border-box;
  background: var(--input-text-field-color);
  border: none;
  box-shadow: var(--inset-box-shadow);
  border-radius: 4px;
}

.salary-input,
.bonus-input,
.bonus-input:focus,
.other-input,
.other-input:focus,
.paid-vacation-input,
.yoe-input,
.yace-input,
.location-input,
.email-input {
  padding-left: 0.75rem !important;
  margin-left: 0px !important;
  height: 38px;
  font-size: 1rem;
  color: var(--input-text-font-color);
  box-sizing: border-box;
  background: var(--input-text-field-color) !important;
  border: none !important;
  box-shadow: var(--inset-box-shadow) !important;
  border-radius: 4px;
}

.error-input {
  color: var(--error-color) !important;
  border: 1px solid var(--error-color) !important;
  box-shadow: var(--inset-box-shadow-danger) !important;
}


.radio-button-group .form-check {
  display: inline-block;
  margin-right: 10px;
}

.custom-textarea,
.custom-textarea:focus,
.custom-textarea:active {
  padding-left: 0.75rem !important;
  margin-left: 0px !important;
  font-size: 1rem;
  color: var(--input-text-font-color) !important;
  box-sizing: border-box;
  background: var(--input-text-field-color) !important;
  border: none !important;
  box-shadow: var(--inset-box-shadow) !important;
  border-radius: 4px !important;
}

.paid-vacation-input::placeholder,
.custom-textarea::placeholder,
.yoe-input::placeholder,
.yace-input::placeholder,
.location-input::placeholder,
.email-input::placeholder { 
  color: var(--placeholder-text-font-color) !important;
  font-size: 1rem;
}