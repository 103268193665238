:root {
  --primary-color: #EAE7F7;
  --secondary-color: #f5f4fa;
  --tertiary-color: #E6E1FA;
  --accent-color: #fbbc04; /* #ffb084 */ 
  --primary-text-color: #555; /* #555 */ 
  --secondary-text-color: #777; /* #777 */
  --error-color: #DF5454;

  --background-color: var(--secondary-color); /* #fff */
  --header-color: linear-gradient(180deg, var(--primary-color) 80%, rgba(255, 255, 255, 0) 100%); /* #7C8B94 */
  --table-stripe-color: var(--primary-color); /* #f6f7f9 */
  --table-hover-color: var(--tertiary-color);

  --inset-box-shadow: inset -1px -1px 4px rgba(255, 255, 255, 0.4), inset -2px -2px 6px rgba(255, 255, 255, 0.2), inset 1px 1px 4px rgba(0, 0, 0, 0.2), inset 2px 2px 6px rgba(0, 0, 0, 0.4);
  --outset-box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.75), -4px -4px 6px rgba(255, 255, 255, 0.25), 2px 2px 6px rgba(0, 0, 0, 0.1), 1px 1px 6px rgba(0, 0, 0, 0.5), inset 2px 2px 6px rgba(255, 255, 255, 0.5);
  --inset-box-shadow-danger: 0px 0px 6px var(--error-color), inset -1px -1px 4px rgba(255, 255, 255, 0.4), inset -2px -2px 6px rgba(255, 255, 255, 0.2), inset 1px 1px 4px rgba(0, 0, 0, 0.2), inset 2px 2px 6px rgba(0, 0, 0, 0.4);
  --outset-box-shadow-danger: -1px -1px 2px rgba(255, 255, 255, 0.75), -4px -4px 6px rgba(255, 255, 255, 0.25), 2px 2px 6px rgba(0, 0, 0, 0.1), 1px 1px 6px rgba(0, 0, 0, 0.5), inset 0px 0px 6px var(--error-color), inset 2px 2px 6px rgba(255, 255, 255, 0.5);

  --primary-button-color: #4685ff; /* #0054fd */ 
  --primary-button-color-hover: #0054fd; /* #0054fd */ 
  --primary-button-color-focus: #0054fd; /* #0054fd */ 
  --primary-button-border-color: #4685ff; /* #4685ff */ 
  --primary-button-background-gradient: linear-gradient(
    90deg,
    rgba(68, 255, 154, 1) -0.55%,
    rgba(68, 176, 255, 1) 22.86%,
    rgba(139, 68, 255, 1) 48.36%,
    rgba(255, 102, 68, 1) 73.33%,
    rgba(235, 255, 112, 1) 99.34%
  );
  --secondary-button-color: var(--primary-color); /* #f2f2f2 */
  --secondary-button-color-hover: var(--primary-color); /* #f2f2f2 */ 
  --secondary-button-color-focus: var(--primary-color); /* #f2f2f2 */ 
  --secondary-button-color-active: var(--primary-color); /* #f2f2f2 */ 
  --secondary-button-border-color: var(--primary-color); /* gray */ 
  --filter-button-color: var(--secondary-color); /* #f2f2f2 */
  --filter-button-color-hover: var(--secondary-color); /* #f2f2f2 */ 
  --filter-button-color-focus: var(--secondary-color); /* #f2f2f2 */ 
  --filter-button-border-color: var(--secondary-color); /* gray */ 

  --section-color: var(--secondary-color);
  --section-border-color: var(--secondary-color);

  --input-text-field-color: var(--secondary-color);

  --divider-color: #555;

  --hero-text-color: #5684F7;
  --sub-text-color: #555;
  
  --header-text-color: var(--primary-text-color); 
  --subheader-text-color: var(--primary-text-color);
  --primary-button-font-color: #fff; /* #fff */ 
  --secondary-button-font-color: var(--primary-text-color); 
  --filter-button-font-color: var(--primary-text-color);
  --input-text-font-color: var(--primary-text-color);
  --placeholder-text-font-color: var(--secondary-text-color);
  --link-text-color: #4685ff; /* #4685ff */ 
}